export default {
    init() {
        // JavaScript to be fired on all pages
        $.fn.toggleText = function (t1, t2) {
            if (this.text() === t1) {
                this.text(t2);
            } else {
                this.text(t1);
            }
            return this;
        };

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $(document).ready(function ($) {
            $('.single-product .woocommerce-product-gallery__image > a').attr('data-fancybox', 'group');

            $('.js-products-slider').slick({
                autoplay: true,
                autoplaySpeed: 3000,
                draggable: true,
                arrows: true,
                prevArrow: $('.js-product-slider-nav-prev'),
                nextArrow: $('.js-product-slider-nav-next'),
                speed: 900,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            arrows: true,
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: true,
                        },
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                        },
                    },
                ],
            });

            $('.js-product-categories-slider').slick({
                autoplay: true,
                autoplaySpeed: 3000,
                draggable: true,
                arrows: false,
                speed: 900,
                infinite: true,
                dots: true,
                appendDots: $('.js-product-categories-slider-dots'),
                slidesToShow: 1,
                centerMode: true,
                variableWidth: true,
            });
        });

        let customArticleToggles = $('.custom_article_number__wrap');

        if (customArticleToggles.length) {
            customArticleToggles.each(function () {
                let toggle = $(this).find('.custom_article_number__toggle');
                let form = $(this).find('.custom_article_number__form');

                toggle.on('click', function (e) {
                    e.preventDefault();

                    form.toggleClass('custom_article_number__form--open');
                });
            });
        }

        function UpdateCartReferences() {
            let cartReferences =  $( document.body ).find('.cart__reference');

            cartReferences.each(function () {
                let reference = $(this).find('.cart__reference__toggle');
                let referenceWrap = $(this).find('.cart__reference__wrap');
                let referenceInput = $(this).find('.cart__reference__input');
                let referenceSave = $(this).find('.cart__reference__save');

                referenceSave.on('click', function (e) {
                    e.preventDefault();

                    $('button[name="update_cart"]').click();
                });

                reference.on('click', function (e) {
                    e.preventDefault();
                    console.log('clicked');
                    referenceWrap.toggleClass('cart__reference__wrap--open');

                    referenceInput.focus();
                });
            });
        }

        function hideEmptyFilters() {
            $('.wpfMainWrapper > .wpfFilterContent').each(function () {
                let optionsList = $(this).find('ul');
                if (optionsList.has('li').length === 0) {
                    $(this).hide();
                }
            });
        }

        function addToggleToFilters() {
            $('.wpfMainWrapper .wpfFilterContent').each(function () {
                let optionsList = $(this).find('.wpfCheckboxHier');
                let optionHeader = $(this).find('.wfpDescription');

                optionsList.collapse();
                optionHeader.on('click', function () {
                    optionsList.collapse('toggle');
                });

                optionsList.on('hidden.bs.collapse', function () {
                    optionHeader.addClass('filter__closed');
                    optionHeader.removeClass('filter__open');
                });

                optionsList.on('shown.bs.collapse', function () {
                    optionHeader.addClass('filter__open');
                    optionHeader.removeClass('filter__closed');
                })
            });
        }

        if ($('.wpfMainWrapper').length) {
            addToggleToFilters();
            hideEmptyFilters();

            $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
                addToggleToFilters();
                hideEmptyFilters();
            });
        }

        if ($('.woocommerce-cart').length) {
            UpdateCartReferences();

            $(document).on('wc_fragments_refreshed', function () {
                console.log('wc_fragments_refreshed');
                UpdateCartReferences();
            });

            $(document).on('updated_wc_div', function () {
                console.log('updated_wc_div');
                UpdateCartReferences();
            });
        }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
