export default {
    init() {
        /* eslint-disable */
        $(document).ready(function() {
            $('.single-product.woocommerce .product__image a').each(function() {
                // Calculate aspect ratio and store it in HTML data- attribute
                let aspectRatio = $(this).width()/$(this).height();
                $(this).data("aspect-ratio", aspectRatio);

                // Conditional statement
                if(aspectRatio > 1) {
                    // Image is landscape
                    $(this).addClass('landscape');
                } else if (aspectRatio < 1) {
                    // Image is portrait
                    $(this).addClass('portrait');
                } else {
                    // Image is square
                    $(this).addClass('square');
                }
            });
        });
        /* eslint-enable */

        $(document).ready(function($){
            const colorChoices = $('li.color-variable-item');
            colorChoices.hover(function(){
                const color = $(this).attr('title')
                $(this).attr('title', '')
                $(this).append($('<span/>', {html: color, class: 'color-variable-item-tooltip'}))
            }, function() {
                $(this).attr('title', $(this).find('.color-variable-item-tooltip').html())
                $(this).find('.color-variable-item-tooltip').remove()
            });
        });

        $('.product__images__slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            fade: false,
            prevArrow: $('.product__images__prev'),
            nextArrow: $('.product__images__next'),
            asNavFor: '.product__thumb__slider',
        });

        $(document).on('change', '.variations select', function () {
            const $Selectedselectboxes = $(document).find('.variations select option:selected');
            const $selectboxes = $(document).find('.variations select');
            //const $videoIndex = $(document).find('.product__images__slider .product__video');
            let $slickGoToNumberSlide = 0;

            //Disabled because video's are shown last now
            //if($videoIndex.length){
            //    $slickGoToNumberSlide = $slickGoToNumberSlide + $videoIndex.length;
            //}

            if ($selectboxes.length === $Selectedselectboxes.length) {
                $(document).find('.product__thumb__slider:first').slick('slickGoTo', $slickGoToNumberSlide);
            }
        });

        $('.product__thumb__slider').slick({
            slidesToShow: 4,
            infinite: false,
            slidesToScroll: 1,
            asNavFor: '.product__images__slider',
            dots: false,
            arrows: false,
            swipeToSlide: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        vertical: false,
                    },
                },
            ],
        });

        $(document).on('click', '.woocommerce__product__highlight__related__button', function (e) {
            e.preventDefault();

            const $relatedProducts = $(document).find('.related-products');
            const $header = $(document).find('header.header');
            const headerHeight = $(window).innerWidth() > 991 ? $header.outerHeight() - 60 : 0;

            if ($relatedProducts.length) {
                $('html, body').animate({
                    scrollTop: $relatedProducts.offset().top - headerHeight,
                }, 500);
            }
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
